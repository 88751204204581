import { ComponentPropsWithoutRef } from 'react'

import { ContentfulBox } from '@/components/Contentful/common/ContentfulBox'

import { useEntry } from '../App.hooks'

export function AppBox(props: Omit<ComponentPropsWithoutRef<typeof ContentfulBox>, 'entry'>) {
	const entry = useEntry()
	return <ContentfulBox {...props} entry={entry} />
}
